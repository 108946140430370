.w3ds-app {
    text-align: center;
    font-size: 14px;
}

.bg-w3ds {
    background: #eefdff;
}

.text-w3ds {
    color: #3cabc1;
}


a {
    color: #3cabc1;
}

.form-control {
    font-size: 14px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.hv-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.border-red {
    border: 1px solid #ff0000;
}

h1,h2,h3,h4,h5 {
    color: #3cacc2;
}

h2 {
    margin: 25px 0;
}
